<template>
  <EdgeStack :id="esId" :busy="busy">
    <template v-slot:header>
      <div class="pb-3 panel-title">{{ getTitleText }}</div>

      <XStepper
        :id="esId"
        :steps="stepperSteps"
        :mode="stepperMode"
        v-model="stepperCurrentStep"
        ref="xstepper"
      />
    </template>

    <template v-slot:footer>
      <XStepperNavigation
        v-if="isEditing"
        :id="esId"
        :steps="stepperSteps"
        :submit-button-text="getSubmitButtonText"
        @submit="onSubmit"
        v-model="stepperCurrentStep"
      />

      <AppButton
        v-if="!isEditing"
        :text="getSubmitButtonText"
        @click="onSubmit({ step: stepperCurrentStep })"
      />
    </template>

    <template #default>
      <keep-alive>
        <template v-if="stepperCurrentStep === 1">
          <AddMultipleScooterStep1
            ref="step1"
            :iotTypes="lockTypes"
            :iotManufacturers="iotManufacturers"
            :vehicleTypes="vehicleTypes"
            :vehicleManufactures="vehicleManufacturers"
            :vehicleModels="vehicleModels"
            @save="onSave($event)"
            @dirty="onDirty(true)"
          />
        </template>

        <template v-else-if="stepperCurrentStep === 2">
          <AddMultipleScooterStep2
            ref="step2"
            @save="onSave($event)"
            @dirty="onDirty(true)"
          />
        </template>
      </keep-alive>
    </template>
  </EdgeStack>
</template>

<script>
import { AppButton } from '@/components/form'
import { EdgeStack } from '@/components/modals'
import { XStepper, XStepperNavigation } from '@/components/stepper'

import { useEndpoints } from '@/composables'
import { VehicleModelConfig } from '@/config/VehicleModelConfig'
import AddMultipleScooterStep1 from '@/views/scooter/AddMultipleScooterStep1.vue'
import AddMultipleScooterStep2 from '@/views/scooter/AddMultipleScooterStep2.vue'

import { SingleScooterConfig } from '@/config/SingleScooterConfig'

export default {
  name: 'AddEditMultipleVehicle',

  components: {
    EdgeStack,
    XStepper,
    XStepperNavigation,
    AppButton,
    AddMultipleScooterStep1,
    AddMultipleScooterStep2,
  },

  props: {
    esId: {
      type: String,
      default: 'vehicle-add-edit',
    },
    stepperMode: {
      type: String,
      default: 'free',
    },
    stepperStep: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      stepperSteps: [
        {
          title: 'Template',
          free: true,
        },
        {
          title: 'Upload',
          free: false,
        },
      ],

      stepperCurrentStep: 1,

      lockTypes: [],
      iotManufacturers: [],
      vehicleManufacturers: [],
      vehicleTypes: [],
      vehicleModels: [],
    }
  },

  computed: {
    isEditing() {
      return true
    },

    getTitleText() {
      return 'Add Multiple Vehicle'
    },

    getSubmitButtonText() {
      if (this.stepperCurrentStep > this.stepperSteps.length - 1)
        return this.$t('components.stepNavigation.finish')

      return 'Download Sample Template'
    },
  },

  async created() {
    await this.fetchData({ onlyUnassignedLocks: false })
  },

  mounted() {
    this.$edgeStack.emitter.on(
      this.$edgeStack.getEventName('closed', this.esId),
      () => {
        this.stepperCurrentStep = 1
      }
    )
  },

  beforeDestroy() {
    this.$edgeStack.emitter.off(
      this.$edgeStack.getEventName('closed', this.esId)
    )
  },

  watch: {
    stepperStep: {
      deep: false,
      immediate: true,
      handler(updatedStep) {
        this.stepperCurrentStep = updatedStep
        console.log('st', this.stepperCurrentStep)
      },
    },
  },

  methods: {
    onDirty(type, id = this.esId) {
      return type === true
        ? this.$edgeStack.shouldConfirm(id)
        : this.$edgeStack.shouldNotConfirm(id)
    },

    async onSave(e = { step: null, data: {} }) {
      console.log(e)
      if (e.step === 1) {
        const steps = [
          {
            title: 'Template',
            free: true,
          },
          {
            title: 'Upload',
            free: true,
          },
        ]
        this.$xStepper.defineSteps(this.esId, steps)
        this.beforeNextStep({ to: 2 })
      }

      if (e.step === 2) {
        this.$xStepper.defineSteps(this.esId, this.stepperSteps)
        this.onLastStep()

        this.$store.dispatch('fsTable/fetchData')
      }
    },

    beforeNextStep({ to = 2 }) {
      if (this.stepperMode === 'free') {
        this.$edgeStack.shouldNotConfirm(this.esId)
      } else {
        this.$edgeStack.shouldConfirm(this.esId)
      }

      if (to) {
        this.$xStepper.navigate(this.esId).to(to)
      } else {
        this.$xStepper.navigate(this.esId).next()
      }
    },

    onLastStep() {
      this.$emit('refresh')

      this.$edgeStack.shouldNotConfirm(this.esId)
      this.$edgeStack.close(this.esId)

      this.$edgeStack.emitter.on(
        this.$edgeStack.getEventName('closed', this.esId),
        () => {
          this.stepperCurrentStep = 1
        }
      )
    },

    onSubmit(step) {
      this.$refs[`step${step.step}`].submit()
    },

    async fetchData() {
      this.lockTypes = await this.$http
        .get(useEndpoints.dropdown.lockTypes())
        .then((res) => res.data.data)
        .catch((err) => console.log('lockTypesErr = ', err.response))

      this.iotManufacturers = await this.$http
        .get(SingleScooterConfig.api.iot_manufacture)
        .then((res) => res.data.data)
        .catch((err) => console.log('err = ', err))

      this.vehicleManufacturers = await this.$http
        .get(useEndpoints.dropdown.vehicleManufacturers())
        .then((res) => res.data.data)
        .catch((err) => console.log('vehicleManufacturersErr = ', err.response))

      this.vehicleModels = await this.$http
        .get(VehicleModelConfig.api.org.index(this.$org.id))
        .then((res) => res.data.data)
        .catch((err) => console.log('vehicleModelsErr = ', err.response))

      const vehicleTypeMap = {
        'SCOOTER': {
          text: 'Scooter',
          value: 'SCOOTER',
        },
        'SCOOTER PRO': {
          text: 'Scooter Pro',
          value: 'SCOOTER PRO',
        },
        'EBIKE': {
          text: 'E-Bike',
          value: 'EBIKE',
        },
        'BIKE': {
          text: 'Bike',
          value: 'BIKE',
        },
        'COCO': {
          text: 'SuperCoco',
          value: 'COCO',
        },
        'KENOTA': {
          text: 'Kenota',
          value: 'KENOTA',
        },
        'MOPED': {
          text: 'Moped',
          value: 'MOPED',
        },
      }
      const orgVehicleTypes = this?.$org?.vehicle_type || []
      orgVehicleTypes.forEach((t) => this.vehicleTypes.push(vehicleTypeMap[t]))
    },
  },
}
</script>

<style scoped>
.panel-title {
  font-size: 22px;
  font-weight: 500;
  color: #2e2e39;
  margin-top: -30px;
  margin-bottom: 6px;
}
</style>
